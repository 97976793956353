<template>
  <div>
    <div class="air__utils__heading">
      <h5>Operaciones</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <!--    Enlaces-->
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'mainLocations'}">
                <b-iconstack font-scale="1" variant="primary">
                  <b-icon stacked icon="box-seam" />
                </b-iconstack>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'mainLocations'}">
              <h6 class="text-block text-secondary">Configuración de Almacén</h6>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'locations'}">
                <b-iconstack font-scale="1" variant="primary">
                  <b-icon stacked icon="box-seam" />
                </b-iconstack>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'locations'}">
              <h6 class="text-block text-secondary">Gestión de Ubicaciones</h6>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'warehouses'}">
                <b-iconstack font-scale="1" variant="primary">
                  <b-icon stacked icon="gear-wide-connected" />
                </b-iconstack>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'warehouses'}">
              <h6 class="text-block text-secondary">Transacciones</h6>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'warehousesCatalogs'}">
                <b-iconstack font-scale="1" variant="primary">
                  <b-icon stacked icon="book"/>
                </b-iconstack>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <h6 class="text-block text-secondary">Catálogos de Almacenes</h6>
          </div>
        </div>
      </div>
    </div>
    <!--    Enlaces-->
  </div>
</template>

<script>
export default {
  name: 'warehouseMenu',
  data() {
    return {
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'operations',
          breadcrumbName: 'Operaciones',
        },
        {
          breadcrumbName: 'Menú de Almacén',
          aIcon: '',
        },
      ],
      resource: '',
      resourceName: 'Servicios',
      searchInput: null,
    }
  },
}
</script>
